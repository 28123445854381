import CryptoJS from 'crypto-js';

export const encryptData = (data) => {
    const key = process.env.ENCRYPT_KEY || 'your-secret-key';
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    return ciphertext;
};



