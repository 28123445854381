import { Route } from "react-router-dom";
import AdminReferAndEarn from "../admin/AdminReferAndEarn";
import ProtectedRoute from "../components/ProtectedRoute";
import AdminBanners from "../admin/AdminBanners";
import AdminNotification from "../admin/AdminNotification";
import AdminGallery from "../admin/AdminGallery";
import AdminQueries from "../admin/AdminQueries";
import AdminBulkEmail from "../admin/AdminBulkEmail";
import AdminPayments from "../admin/AdminPayments";
import EditUser from "../admin/EditUser";
import AdminResellers from "../admin/AdminResellers";
import AdminUsers from "../admin/AdminUsers";
import AdminAddCoupon from "../admin/AdminAddCoupon";
import PhotoUpload from "../admin/PhotoUpload";
import AdminAddBrand from "../admin/AdminAddBrand";
import AdminEditProduct from "../admin/AdminEditProduct";
import AdminAddProduct from "../admin/AdminAddProduct";
import AdminProduct from "../admin/AdminProduct";
import AdminViewOrder from "../admin/AdminViewOrder";
import AdminDashboard from "../admin/AdminDashboard";
import AdminLogin from "../admin/AdminLogin";
import PublicRoute from "../components/PublicRoute";
import AdminOrder from "../admin/AdminOrder";
import AdminAddFunds from "../admin/AdminAddFunds";
import AdminFlashSale from "../admin/AdminFlashSale.js";


export const adminRoutes = [
    <Route
        path="/admin-login"
        element={
            <PublicRoute>
                <AdminLogin />
            </PublicRoute>
        }
    />,
    <Route
        path="/admin-dashboard"
        element={
            <ProtectedRoute>
                <AdminDashboard />
            </ProtectedRoute>
        }
    />,
    <Route
        path="/admin-orders"
        element={
            <ProtectedRoute>
                <AdminOrder />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-view-order/:id?"
        element={
            <ProtectedRoute>
                <AdminViewOrder />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-products"
        element={
            <ProtectedRoute>
                <AdminProduct />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-add-product"
        element={
            <ProtectedRoute>
                <AdminAddProduct />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-edit-product/:id?"
        element={
            <ProtectedRoute>
                <AdminEditProduct />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-add-brand"
        element={
            <ProtectedRoute>
                <AdminAddBrand />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-test"
        element={
            <ProtectedRoute>
                <PhotoUpload />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-add-coupon"
        element={
            <ProtectedRoute>
                <AdminAddCoupon />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-users"
        element={
            <ProtectedRoute>
                <AdminUsers />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-resellers"
        element={
            <ProtectedRoute>
                <AdminResellers />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-edit-user/:id?"
        element={
            <ProtectedRoute>
                <EditUser />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-payments"
        element={
            <ProtectedRoute>
                <AdminPayments />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-bulk-email"
        element={
            <ProtectedRoute>
                <AdminBulkEmail />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-queries"
        element={
            <ProtectedRoute>
                <AdminQueries />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-gallery"
        element={
            <ProtectedRoute>
                <AdminGallery />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-notification"
        element={
            <ProtectedRoute>
                <AdminNotification />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-funds"
        element={
            <ProtectedRoute>
                <AdminAddFunds />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-banners"
        element={
            <ProtectedRoute>
                <AdminBanners />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-refer-earn"
        element={
            <ProtectedRoute>
                <AdminReferAndEarn />
            </ProtectedRoute>
        }
    />
    ,
    <Route
        path="/admin-flash-sale"
        element={
            <ProtectedRoute>
                <AdminFlashSale />
            </ProtectedRoute>
        }
    />
]