import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { message } from "antd";
import IMAGES from "../../img/image";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StorefrontIcon from "@mui/icons-material/Storefront";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import WalletIcon from "@mui/icons-material/Wallet";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LoginIcon from "@mui/icons-material/Login";
import "./SideMenu.css";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [submenu, setSubmenu] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div
      className={`sidemenu-container d-block d-md-block d-lg-none ${sideMenu ? "active" : ""
        }`}
    >
      <div className="sidemenu">
        <HighlightOffIcon
          onClick={() => setSideMenu(!sideMenu)}
          className="close-icon"
        />
        <ul className="p-0">
          {user && (
            <li onClick={() => setSideMenu(!sideMenu)}>
              <Link to="/user-dashboard">
                <DashboardIcon className="icon me-1" />
                Dashboard
              </Link>
            </li>
          )}
          {user && (
            <li onClick={() => setSideMenu(!sideMenu)}>
              <Link to="/my-account">
                <AccountCircleIcon className="icon me-1" />
                Account
              </Link>
            </li>
          )}
          {user && (
            <li onClick={() => setSideMenu(!sideMenu)}>
              <Link to="/orders">
                <StorefrontIcon className="icon me-1" />
                Orders
              </Link>
            </li>
          )}
          {user && (
            <li onClick={() => setSideMenu(!sideMenu)}>
              <Link to="/query">
                <QuestionAnswerIcon className="icon me-1" />
                Queries
              </Link>
            </li>
          )}
          {user && (
            <li onClick={() => setSideMenu(!sideMenu)}>
              <Link to="/wallet">
                <WalletIcon className="icon me-1" />
                Wallet
              </Link>
            </li>
          )}
          <li onClick={() => setSideMenu(!sideMenu)}>
            <Link onClick={() => setSideMenu(!sideMenu)} to="/games">
              <SportsEsportsIcon className="icon me-1" />
              Games
            </Link>
          </li>
          <li onClick={() => setSideMenu(!sideMenu)}>
            <Link onClick={() => setSideMenu(!sideMenu)} to="/contact">
              <SupportAgentIcon className="icon me-1" />
              Contact
            </Link>
          </li>

          {!user && (
            <li>
              <Link to="/login">
                <LoginIcon className="icon me-1" />
                Login
              </Link>
            </li>
          )}

          {!user && (
            <div className="sidemenu-action-btn">
              <Link to="/register">
                <LoginIcon className="icon me-1" />
                Sign Up
              </Link>
            </div>
          )}

          {user && (
            <div className="logout" onClick={handleLogout}>
              Logout
              <LogoutIcon className="icon" />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
